import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function NewAlbum() {
  return (
    <section id="new_album" className="spacey">
      <StaticImage
        as="figure"
        src="../images/albums/just-to-please-you.jpg"
        alt="Just to Please You cover"
        loading="eager"
        objectFit="contain"
      />
      <article className="space">
        <h2 className="upper">
          Latest Album: <i>Just to Please You</i>
        </h2>
        <p>
          Just to Please You is the group’s first full length album
          collaboration with Neptune’s Machine Producer Jason Mercer (ani
          difranco, Ron Sexsmith). Taking inspiration from artists like Wilco
          and The National, The Gertrudes knit together a sound like an old-time
          saloon party travelling through deep space. Just to Please You follows
          four full length albums and extensive touring across the country,
          including memorable performances like at the Vancouver Folk Festival
          and the Halifax Pop Explosion, and sharing the stage with artists like
          Sarah Harmer, Calexico, and Ricky Skaggs.
        </p>

        <a
          href="JustToPleaseYou_PressPage_FORWEB_22Mar2023.pdf"
          className="button yellow_border">
          Learn More
        </a>
      </article>
    </section>
  );
}
